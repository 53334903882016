import { useState, useEffect } from 'react'
import { ParsedQuery } from 'query-string'
import { useHistory } from 'react-router-dom'
import { replaceParam, useQueryStringParams } from '../../utils/queryParams';
import { Product as ShopProduct } from '../../data/shop'

type ProductFilters = {
  availableFilters: string[],
  selectedFilters: string[],
  toggleFilter: (filter: string) => void,
  removeFilter: (filter: string) => void,
  addFilter: (filter: string) => void,
  selectAllFilters: () => void,
}

const sortAlphabetically = (a: string, b: string) => a.localeCompare(b)

interface QueryParams extends ParsedQuery<string | number | boolean> {
  collection: string
}

export default function useProductFilters (products?: ShopProduct[]): ProductFilters {
  const history = useHistory()
  const [availableFilters, setAvailable] = useState<string[]>([])
  const [selectedFilters, setSelected] = useState<string[]>([])
  const urlParams = useQueryStringParams<QueryParams>()

  useEffect(() => {
    if (products) {
      const available = products
        .map(product => product.collections as string[])
        .flat()
        .filter(collection => !!collection)
        .filter((type, index, self) => self.indexOf(type) === index)
        .sort(sortAlphabetically)

      const all = [...available, 'Anthologies'];
      setAvailable(all)

      if (urlParams.collection) {
        const selected = urlParams.collection.split(',').filter(collection => all.includes(collection)) as string[]
        setSelected(selected)
      } else {
        setSelected(all)
      }
    }
  }, [products, urlParams.collection])

  const selectFilters = (filters: string[]) => {
    setSelected(filters)
    history.push({search: replaceParam<QueryParams>(urlParams, 'collection', filters.join(','))})
  }

  const removeFilter = (filter: string) => selectFilters(selectedFilters.filter(t => t !== filter));
  const addFilter = (filter: string) => selectFilters([...selectedFilters, filter]);
  const selectAllFilters = () => selectFilters([]);

  const toggleFilter = (filter: string) => {
    if (selectedFilters.length === availableFilters.length) {
      selectFilters([filter])
    } else if (selectedFilters.includes(filter) && selectedFilters.length > 1) {
      removeFilter(filter)
    } else if (selectedFilters.includes(filter)) {
      selectAllFilters()
    } else {
      // Desculpa, Claudia futura!! Estou um bocado cansada.
      selectFilters([filter])
      // addFilter(filter)
    }
  }

  return {
    availableFilters,
    selectedFilters,
    toggleFilter,
    removeFilter,
    addFilter,
    selectAllFilters
  }
}
