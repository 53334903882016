import styled, { css } from 'styled-components'
import { light, primary, secondaryDark, primaryDark } from '../../styles/colors';
import useFilters from "./useProductFilters";

export const useProductFilters = useFilters;

interface Props {
  availableFilters: string[],
  selectedFilters: string[],
  toggleFilter: (filter: string) => void,
  selectAllFilters: () => void,
}

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding: 1em 1em 0 1em;
`

const FilterButton = styled.button<{selected: boolean}>`
  font-size: 1rem;
  appearance: none;
  border: none;
  cursor: pointer;
  padding: 0.2em 0.4em;
  opacity: 0.8;
  color: ${primary};
  background-color: ${light};
  border-radius: 0.3em 0.6em;
  text-decoration: none;
  font-weight: 500;
  transition: all 1s ease;
  margin: 0 0.5em;

  &:hover, &:focus {
    opacity: 1;
    color: ${primaryDark};
  }

  &:active {
    color: ${light};
    background-color: ${secondaryDark};
  }

  ${props => props.selected && css`
    color: ${light};
    background-color: ${primary};

    &:hover, &:focus {
      color: ${light};
    }
  `}
`

export default function ProductFilters({
  availableFilters,
  selectedFilters,
  toggleFilter,
  selectAllFilters
}: Props) {
  return (
    <Filters>
      {availableFilters.length > 0 && (
        <FilterButton
          onClick={() => selectAllFilters()}
          disabled={selectedFilters.length === availableFilters.length}
          selected={selectedFilters.length === availableFilters.length}
        >Show Everything</FilterButton>
      )}

      {availableFilters.map((filter: string) => (
        <FilterButton
          key={filter}
          selected={selectedFilters.includes(filter) && selectedFilters.length !== availableFilters.length}
          onClick={() => toggleFilter(filter)}
        >{filter}</FilterButton>
      ))}
    </Filters>
  )
}
