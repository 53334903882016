import styled from 'styled-components';
import AboutMe from '../components/AboutMe';
import Page from '../components/Page'
import { breakpoints, fromMedium, fromTablet } from '../styles/mediaQueries';
import { PageTitle } from '../components/ui/PageTitle';

import phobos from '../components/portfolio/images/covers/midSize/phobos-1.jpg';
import worldsApart from '../components/portfolio/images/covers/midSize/worlds-apart.jpg';
import theSeaWallOfPlymouth from '../components/portfolio/images/covers/midSize/plymouth-1.jpg';
import theLastDayofRain1 from '../components/portfolio/images/covers/midSize/ldor-9.jpg';
import theLastDayofRain2 from '../components/portfolio/images/covers/midSize/ldor-10.jpg';
import theLastDayofRain3 from '../components/portfolio/images/covers/midSize/ldor-3.jpg';
import distantConnections1 from '../images/anthology-covers/distantconnections-1.jpg';
import distantConnections2 from '../images/anthology-covers/distantconnections-2.jpg';
import distantConnections3 from '../images/anthology-covers/distantconnections-3.jpg';
import distantConnections4 from '../images/anthology-covers/distantconnections-4.jpg';
import wipXL from '../images/anthology-covers/wip-xl.jpg';
import change from '../images/anthology-covers/change.jpg';
import tallTalesShortStories from '../images/anthology-covers/talltales-shortstories.jpg';
import { body } from '../styles/fonts';
import { DNoise } from '../components/ui/ImageNoise';


const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 2em 1rem;
  grid-gap: 2rem;
  max-width: ${breakpoints.maxWidth}px;
  
  ${fromMedium} {
    grid-template-columns: 1fr 1fr 1fr;
  }

  ${fromTablet} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  }
`

const SectionTitle = styled.h2`
  font-size: 2.4rem;
  margin: 0.8em 0 0em;
`;

const Book = styled.article`
  display: flex;
  height: 100%;
  flex-direction: column;

  & > a {
    display: block;
    overflow: hidden;
  }
  
  &:focus, &:hover {
    img, &::after {
      transform: scale(1.1);
    }
  }
  
  img {
    transition: all 0.3s ease;
    max-width: 100%;
  }

  h3 {
    margin: 0.5em 0 0.5em;
    font-family: ${body};
    font-weight: 500;
  }

  aside {
    display: flex;
    justify-content: space-between;
    margin-top: auto;

    time {
      opacity: 0.8;
      font-weight: 400;
    }

    a {
      display: block;
    }
  }
`;

export default function Publications() {
  return (
    <Page>
      <PageTitle>Books & Comics</PageTitle>

      {/* <SectionTitle>Self-Published</SectionTitle> */}
      <Grid>
      <Book>
        <a href="https://shop.claudiamatosa.com/products/worlds-apart-mini-55980" target="_blank" rel="noreferrer">
          <DNoise>
            <img src={worldsApart} alt="Worlds Apart" />
          </DNoise>
        </a>
          <h3>Worlds Apart</h3>
          <aside>
            <time dateTime="2021">2021</time>
            <a href="https://shop.claudiamatosa.com/products/worlds-apart-mini-55980" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://shop.claudiamatosa.com/products/phobos-illustrated-book-44-94483" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={phobos} alt="Phobos" />
            </DNoise>
          </a>
          <h3>Phobos</h3>
          <aside>
            <time dateTime="2022">2022</time>
            <a href="https://shop.claudiamatosa.com/products/phobos-illustrated-book-44-94483" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://shop.claudiamatosa.com/products/the-sea-wall-of-plymouth-comic-book-and-46850" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={theSeaWallOfPlymouth} alt="The Sea Wall of Plymouth" />
            </DNoise>
          </a>
          <h3>The Sea Wall of Plymouth</h3>
          <aside>
            <time dateTime="2023">2023</time>
            <a href="https://shop.claudiamatosa.com/products/the-sea-wall-of-plymouth-comic-book-and-46850" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={theLastDayofRain1} alt="The Last Day of Rain - Chapter One" />
            </DNoise>
          </a>
          <h3>The Last Day of Rain: One</h3>
          <aside>
            <time dateTime="2022">2022</time>
            <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={theLastDayofRain2} alt="The Last Day of Rain - Chapter Two" />
            </DNoise>
          </a>
          <h3>The Last Day of Rain: Two</h3>
          <aside>
            <time dateTime="2023">2023</time>
            <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={theLastDayofRain3} alt="The Last Day of Rain - Chapter Three" />
            </DNoise>
          </a>
          <h3>The Last Day of Rain: Three</h3>
          <aside>
            <time dateTime="2024">2024</time>
            <a href="https://thelastdayofrain.com" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>
      </Grid>

      <SectionTitle>Anthology Contributions</SectionTitle>

      <Grid>
        <Book>
          <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={distantConnections1} alt="The Lockdown Lowdown #1" />
            </DNoise>
          </a>
          <h3>The Lockdown Lowdown #1</h3>
          <aside>
            <time dateTime="2021">2021</time>
            <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
        <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">
          <DNoise>
            <img src={distantConnections2} alt="The Lockdown Lowdown #2" />
          </DNoise>
        </a>
          <h3>The Lockdown Lowdown #2</h3>
          <aside>
            <time dateTime="2022">2022</time>
            <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={distantConnections3} alt="Women and Covid: a Gendered Pandemic" />
            </DNoise>
          </a>
          <h3>Women and Covid: a Gendered Pandemic</h3>
          <aside>
            <time dateTime="2023">2023</time>
            <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={distantConnections4} alt="Finding Our Future" />
            </DNoise>
          </a>
          <h3>Finding Our Future</h3>
          <aside>
            <time dateTime="2024">2024</time>
            <a href="https://distantconnections.co.uk/our-zines" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://wipcomics.gumroad.com/l/wipxl" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={wipXL} alt="Change: A WIP Comics Anthology" />
            </DNoise>
          </a>
          <h3>WIP XL: A WIP Comics Anthology</h3>
          <aside>
            <time dateTime="2022">2022</time>
            <a href="https://wipcomics.gumroad.com/l/wipxl" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://www.kickstarter.com/projects/joestone/change-a-wip-comics-anthology" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={change} alt="Change: A WIP Comics Anthology" />
            </DNoise>
          </a>
          <h3>Change: A WIP Comics Anthology</h3>
          <aside>
            <time dateTime="2023">2023</time>
            <a href="https://www.kickstarter.com/projects/joestone/change-a-wip-comics-anthology" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>

        <Book>
          <a href="https://www.kickstarter.com/projects/joestone/tall-tales-and-short-stories-new-wip-comics-anthologies" target="_blank" rel="noreferrer">
            <DNoise>
              <img src={tallTalesShortStories} alt="Tall Tales & Short Stories" />
            </DNoise>
          </a>
          <h3>Tall Tales & Short Stories</h3>
          <aside>
            <time dateTime="2024">2024</time>
            <a href="https://www.kickstarter.com/projects/joestone/tall-tales-and-short-stories-new-wip-comics-anthologies" target="_blank" rel="noreferrer">More info <i className="fa-solid fa-link" /></a>
          </aside>
        </Book>
      </Grid>

      <AboutMe showTitle={false} />
    </Page>
  )
}
