import { ReactNode } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { light, primary, primaryDark, secondary, secondaryDark } from '../../styles/colors'
import { fromSmall } from '../../styles/mediaQueries'

const StyledButton = styled.button<{color?: string}>`
  color: ${light};
  background-color: ${props => props.color === 'primary' ? primaryDark : secondaryDark};
  font-size: 1.1rem;
  border-radius: 0.6em;
  border: none;
  padding: 0.4em 0.8em;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;

  ${fromSmall} {
    font-size: 1.3rem;
  }

  &:hover {
    background-color: ${props => props.color === 'primary' ? secondaryDark : primary};
  }
`

const buttonLinkStyles = css<{color?: string}>`
  padding: 0.5em;
  color: ${light};
  background-color: ${props => props.color === 'secondary' ? secondaryDark : primary};
  border-radius: 0.3em 0.6em;
  text-decoration: none;
  font-weight: 500;
  transition: all 1s ease;

  &:link {
    color: ${light};
    transition: all 1s ease;
  }

  &:hover, &:focus {
    color: ${light};
    background-color: ${props => props.color === 'secondary' ? secondary : primaryDark};
  }

  &:active {
    color: ${light};
    background-color: ${secondaryDark};
  }
`

type Props = {
  color?: 'primary' | 'secondary'
  children?: ReactNode
  type?: 'button' | 'submit' | 'reset'
  disabled?: boolean
  onClick?: () => void
}

function Button ({ children, onClick, color, type, disabled }: Props) {
  return (
    <StyledButton onClick={onClick} color={color} type={type} disabled={disabled}>
      {children}
    </StyledButton>
  )
}

export const ButtonLink = styled.a<{color?: string}>`
  ${buttonLinkStyles}
`;

export const ButtonLinkRouter = styled(Link)<{color?: string}>`
  ${buttonLinkStyles}
`;

export default Button
