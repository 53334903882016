import {getImages as getEtsyImagesForProduct} from './etsy'
import { ListingSources } from '../shared/shop'

export type Price = {
  amount: number
  currency: string
  divisor: number
}

export type Product = {
  shopId: string
  featuredRank: number
  id: string
  name: string
  description: string
  url: string
  price: Price
  multiplePrices: boolean
  quantityAvailable: number
  listingSource: ListingSources
  type?: string
  images?: ProductImage[]
  collections?: string[]
}

export type ProductImage = {
  id: number | string
  rank: number
  thumbnailSmaller: string
  thumbnail: string
  fullSize: string
  height: number
  width: number
}

export function getImagesForProduct (product: Product): Promise<ProductImage[]> {
  switch (product.listingSource) {
    case ListingSources.ETSY:
      return getEtsyImagesForProduct(product.id, product.shopId)
    case ListingSources.SHOPIFY:
      // Shopify returns images in the product request, so we don't need to make an api call here
      return product.images ? Promise.resolve(product.images) : Promise.resolve([])
    default:
      throw new Error(`Unsupported listing source: ${product.listingSource}`)
  }
}
