import { useQuery } from 'react-query'
import styled from 'styled-components'
import Page from '../../components/Page'
import { getProducts as getProductsFromShopify } from '../../data/shopify'

import { dark, primaryDark, primary, secondaryDark } from '../../styles/colors'
import { fromMedium } from '../../styles/mediaQueries'
import { Product as ShopProduct } from '../../data/shop'

import Product from '../../components/shop/Product'
import { ButtonLinkRouter } from '../../components/ui/Button'
import links from '../../data/links'
import { useSortedProducts } from '../../utils/products'

const Products = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100vw;
  max-width: 1800px;
`

const InfoContainer = styled.div`
  margin-top: 1em;
  font-size: 1.3rem;
  padding: 1em;
  color: ${dark};
  text-align: center;
  width: 100%;

  ${fromMedium} {
    width: 20em;
  }
`

const ExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  color: ${primary};
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: ${primaryDark};
    text-decoration: underline;
  }

  &:active {
    color: ${secondaryDark};
  }
`

const ButtonsContainer = styled.div`
  text-align: center;
  font-size: 1.2rem;
  margin: 2rem auto;
`

function TheLastDayOfRain () {
  const {data: shopifyProducts, isLoading, isError} = useQuery<ShopProduct[], any>('shopify-products', () => getProductsFromShopify())
  const sortedProducts = useSortedProducts(shopifyProducts)

  const filterLDORBooks = (product: ShopProduct) => {
    return product.name.includes('The Last Day of Rain');
  }

  return (
    <Page>
      {(isLoading) && <InfoContainer>Hold on tight for a few seconds: loading the good stuff!</InfoContainer>}
      {(isError) && <InfoContainer>Oops, there was an error while fetching the list of products... Please refresh the page or go straight to the <ExternalLink href={links.shopify}>Shopify shop</ExternalLink>!</InfoContainer>}

      {sortedProducts && (
        <>
          <Products>
            {sortedProducts?.filter(filterLDORBooks).map(product => (
              <Product product={product} key={product.id} />
            ))}
          </Products>
        </>
      )}

      <ButtonsContainer>
        <ButtonLinkRouter color='secondary' to="/shop">View all comics, prints & stickers!</ButtonLinkRouter>
      </ButtonsContainer>
    </Page>
  )
}

export default TheLastDayOfRain
