import { ShopifyImage, ShopifyListing, GetProductsResponse } from '../shared/shopify'
import { Product, ProductImage } from './shop'
import { ListingSources } from '../shared/shop'

const requestHeaders = new Headers()
requestHeaders.append('Content-Type', 'application/json')
requestHeaders.append('Accept', 'application/json')

const requestOptions = {
  method: 'GET'
}

const mapImage = (image: ShopifyImage, index: number, allImages: ShopifyImage[]): ProductImage => ({
  id: image.id,
  rank: allImages.length - index,
  thumbnailSmaller: `${image.url}&width=800`,
  thumbnail: `${image.url}&width=1200`,
  fullSize: image.url,
  height: image.height,
  width: image.width
})

const mapProduct = (listing: ShopifyListing): Product => ({
  shopId: listing.vendor,
  id: listing.id.toString(),
  name: listing.title,
  featuredRank: parseInt(listing.metafields[1].value, 10) || 1000,
  description: listing.descriptionHtml,
  url: listing.onlineStoreUrl,
  price: {
    amount: listing.priceRange.minVariantPrice.amount,
    currency: listing.priceRange.minVariantPrice.currencyCode,
    divisor: 1 // Shopify returns the right amount, no need to divide
  },
  multiplePrices: listing.priceRange.minVariantPrice.amount !== listing.priceRange.maxVariantPrice.amount,
  quantityAvailable: listing.totalInventory,
  listingSource: ListingSources.SHOPIFY,
  type: listing.productType,
  images: listing.images.map(mapImage),
  collections: JSON.parse(listing.metafields[0].value) || []
})

export async function getProducts(totalEntries?: number): Promise<Product[]> {
  try {
    const queryParams = totalEntries ? `?max=${totalEntries}` : ''

    const response = await fetch(
      `/api/shopify/listings${queryParams}`,
      requestOptions
    )

    if (!response.ok) {
      throw new Error(`Error fetching products from Shopify: ${response.status} ${response.statusText}`)
    }

    const json: GetProductsResponse = await response.json()
    return json.results.map(mapProduct);
  } catch (error) {
    throw new Error(`Error fetching products from Etsy: ${error}`)
  }
}
