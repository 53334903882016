type Convention = {
  date: string,
  location?: string,
  title: string,
  link: string,
}

const conventions: Convention[] = [
  // {
  //   date: '16-17 November 2024',
  //   location: 'Harrogate',
  //   title: 'Thought Bubble Festival',
  //   link: 'https://www.thoughtbubblefestival.com/comicconvention',
  // },
  {
    date: '1-31 July 2025',
    // location: 'Online',
    title: 'LDC Online Comics Fair',
    link: 'https://ldcomics.com/ldcomics-online-fair-2025/'
  }
];

export default conventions;
