import {EtsyImage, EtsyListing, GetImagesResponse, GetProductResponse, GetProductsResponse} from '../shared/etsy'
import {Product, ProductImage} from './shop'
import { ListingSources } from '../shared/shop'

const {
  REACT_APP_ETSY_SHOP_ID_CLAUDIAMATOSA
} = process.env

const requestHeaders = new Headers()
requestHeaders.append('Content-Type', 'application/json')
requestHeaders.append('Accept', 'application/json')

const requestOptions = {
  method: 'GET'
}

const mapProduct = (listing: EtsyListing): Product => ({
  shopId: listing.shop_id.toString(),
  id: listing.listing_id.toString(),
  name: listing.title,
  featuredRank: listing.featured_rank,
  description: listing.description,
  url: listing.url,
  price: {
    amount: listing.price.amount,
    currency: listing.price.currency_code,
    divisor: listing.price.divisor
  },
  multiplePrices: false,
  quantityAvailable: listing.quantity,
  listingSource: ListingSources.ETSY
})

const mapImage = (image: EtsyImage): ProductImage => ({
  id: image.listing_image_id,
  rank: image.rank,
  thumbnailSmaller: image.url_170x135,
  thumbnail: image.url_570xN,
  fullSize: image.url_fullxfull,
  height: image.full_height,
  width: image.full_width
})

export async function getProducts(): Promise<Product[]> {
  try {
    const response = await fetch(
      `/api/etsy/${REACT_APP_ETSY_SHOP_ID_CLAUDIAMATOSA}/listings`,
      requestOptions
    )

    if (!response.ok) {
      throw new Error(`Error fetching products from Etsy: ${response.status} ${response.statusText}`)
    }

    const json: GetProductsResponse = await response.json()
    return json.results.map(mapProduct)
  } catch (error) {
    throw new Error(`Error fetching products from Etsy: ${error}`)
  }
}

export async function getProductsByIds(ids: string[]): Promise<Product[]> {
  try {
    const response = await fetch(
      `/api/etsy/listings/batch?listingIds=${ids.join(',')}`,
      requestOptions
    )

    if (!response.ok) {
      throw new Error(`Error fetching products from Etsy: ${response.status} ${response.statusText}`)
    }

    const json: GetProductsResponse = await response.json()
    return json.results.map(mapProduct)
  } catch (error) {
    throw new Error(`Error fetching products from Etsy: ${error}`)
  }
}

export async function getProduct(id: string): Promise<Product> {
  try {
    const response = await fetch(
      `/api/etsy/${REACT_APP_ETSY_SHOP_ID_CLAUDIAMATOSA}/listings/${id}`,
      requestOptions
    )

    if (!response.ok) {
      throw new Error(`Error fetching product with id ${id} from Etsy: ${response.status} ${response.statusText}`)
    }

    const json: GetProductResponse = await response.json()
    return mapProduct(json)
  } catch (error) {
    throw new Error(`Error fetching product with id ${id} from Etsy: ${error}`)
  }
}

export async function getImages(listingId: string, shopId: string): Promise<ProductImage[]> {
  try {
    const response = await fetch(
      `/api/etsy/${shopId}/listings/${listingId}/images`,
      requestOptions
    )

    if (!response.ok) {
      throw new Error(`Error fetching products from Etsy: ${response.status} ${response.statusText}`)
    }

    const json: GetImagesResponse = await response.json()
    return json.results.map(mapImage)
  } catch (error) {
    throw new Error(`Error fetching products from Etsy: ${error}`)
  }
}
