import {ParsedQuery, parse, stringify} from 'query-string'
import { useEffect, useState } from 'react'
import {useLocation} from 'react-router-dom'

const parseOptions = {
  parseBooleans: true,
  parseNumbers: true,
}

export function useQueryHashParams<
  T extends ParsedQuery<string | number | boolean>,
>(): T {
  const location = useLocation()
  const [params, setParams] = useState<T>({...parse(location.hash, parseOptions) as T})

  useEffect(() => {
    setParams({...parse(location.hash, parseOptions) as T})
  },[location.hash])

  return params
}

export function useQueryStringParams<
  T extends ParsedQuery<string | number | boolean>,
>(): T {
  const location = useLocation()
  const [params, setParams] = useState<T>({...parse(location.search, parseOptions) as T})

  useEffect(() => {
    setParams({...parse(location.search, parseOptions) as T})
  },[location.search])

  return params
}

export function replaceParam<
  T extends ParsedQuery<string | number | boolean>,
>(params: T, param: keyof T, value: T[keyof T]): string {
  const newParams = {...params, [param]: value?.toString()}
  return stringify(newParams)
}
