import LogoIcon from "./icons/Logo"
import styled, { css } from "styled-components"
import { NavLink, useRouteMatch } from "react-router-dom"
// import links from "../data/links"
import { breakpoints, fromDesktop, fromMedium, fromSmall, fromSmallPhone, fromTablet } from "../styles/mediaQueries"
import { body } from "../styles/fonts"
import { light, primary, primaryBackground, primaryDark, primarySubdued, secondaryDark } from "../styles/colors"
import SocialLinks from "./SocialLinks"
import { useState } from "react"
// import NavigationLink from "./NavigationLink"

const Navigation = styled.nav`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: ${breakpoints.maxWidth}px;
  gap: 1.5em;
  flex-wrap: wrap;
  margin: 1rem 0 1rem;
  font-size: 0.65rem;
  position: relative;
  z-index: 200;

  ${fromSmall} {
    margin: 0.5rem 0 1rem;
  }

  ${fromMedium} {
    font-size: 0.7rem;
    gap: 1em;
    margin: 0.5rem 0 0;
  }
`

const LogoContainer = styled.div`
  width: 5rem;
  height: auto;
  transition: all 0.5s ease-in-out;
  /* display: none; */
  
  & > svg {
    width: 100%;
  }

  ${fromSmall} {
    width: 6rem;
  }

  ${fromMedium} {
    width: 5rem;
  }

  ${fromTablet} {
    width: 7rem;
  }

  ${fromSmall} {
    /* display: block; */
  }
`

const Menu = styled.nav`
  font-family: ${body};
  font-size: 1.2rem;

  ul {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    list-style: none;
    padding: 0;
    margin: 0;

    ${fromTablet} {
      gap: 1em;
    }
  }

  a.active {
    color: ${secondaryDark};
  }
`

const MenuContainer = styled.div`
  display: none;

  ${fromMedium} {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

const MobileMenu = styled.div<{$isOpen: boolean}>`
  display: flex;
  position: relative;
  z-index: 2000;
  font-size: 1.5rem;

  ${props => props.$isOpen ? css`
    &::before {
    content: '';
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 2000;
  ` : ''};


  ${fromMedium} {
    display: none;

    &::before {
      display: none;
    }
  }
`;

const MobileMenuTrigger = styled.button<{$isOpen: boolean}>`
  cursor: pointer;
  background-color: rgb(240, 240, 240);
  border: none;
  padding: 0.5em;
  color: ${props => props.$isOpen ? secondaryDark : primaryDark};
  z-index: 2100;
`;

const MobileMenuContainer = styled.div<{$isOpen: boolean}>`
  height: auto;
  display: ${props => props.$isOpen ? 'flex' : 'none'};
  flex-direction: column;
  position: absolute;
  background-color: ${primaryBackground};
  color: ${light};
  top: 3em;
  right: 0;
  font-size: 1.5rem;
  z-index: 2100;

  ${fromSmallPhone} {
    right: auto;
    left: 0;
  }
  
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li a {
    display: block;
    padding: 1em 2em;
    color: ${light};
    border-bottom: solid 1px ${primarySubdued};

    &:focus, &:hover {
      background-color: ${primary};
    }

    &:active, &.active {
      color: ${primaryDark};
      background-color: ${primarySubdued};
    }
  }

  li:last-child a {
    border-bottom: none;
  }
`;

const Columns = styled.div`
  display: none;

  ${fromMedium} {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 0.5em;
  }
  
  ${fromDesktop} {
    margin-left: 0.5rem;
    align-items: center;
    flex-direction: row;
    white-space: nowrap;
    gap: 5em;
  }

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

const SocialBar = styled.div`
  display: none;
  
  ${fromSmallPhone} {
    width: auto;
    display: block;
  }

  ${fromMedium} {
    font-size: 0.9em;
  }

  ${fromTablet} {
    font-size: 1em;
  }
`

function MenuLinks () {
  return (
    <ul>
      <li><NavLink to="/" exact>Home</NavLink></li>
      <li><NavLink to="/portfolio">Portfolio</NavLink></li>
      <li><NavLink to="/publications">Books</NavLink></li>
      <li><NavLink to="/mentoring">Mentoring</NavLink></li>
      <li><NavLink to="/about">About</NavLink></li>
      {/* <li><NavLink to="/shop">Shop</NavLink></li> */}
      <li><a href="https://shop.claudiamatosa.com" target="_blank" rel="noreferrer">Shop</a></li>
      <li><NavLink to="/contact">Contact</NavLink></li>
    </ul>
  );
}

function Header () {
  const {path} = useRouteMatch()
  const isHomepage = path === '/'
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);

  const toggleMobileMenu = () => isMobileMenuOpen ? setIsMobileMenuOpen(false) : setIsMobileMenuOpen(true);

  return (
    <Navigation>
      <LogoContainer>
        <NavLink to="/"><LogoIcon title={isHomepage ? "Claudia Matosa Illustration" : "Back to the homepage"} /></NavLink>
      </LogoContainer>

      <MobileMenu $isOpen={isMobileMenuOpen}>
        <MobileMenuTrigger $isOpen={isMobileMenuOpen} onClick={toggleMobileMenu}>
          <i className="fa-solid fa-bars" /> Navigation
        </MobileMenuTrigger>

        <MobileMenuContainer $isOpen={isMobileMenuOpen}>
          <MenuLinks />
        </MobileMenuContainer>
      </MobileMenu>

      <Columns>
        <MenuContainer>
          <Menu>
            <MenuLinks />
          </Menu>
        </MenuContainer>
      </Columns>

      <SocialBar>
        <SocialLinks short marginTop={false} />
      </SocialBar>
    </Navigation>
  )
}

export default Header
