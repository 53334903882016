import { useQuery } from 'react-query'
import styled from 'styled-components'
import Page from '../components/Page'
import {getProductsByIds as getProductsFromEtsyByIds} from '../data/etsy'
import { getProducts as getProductsFromShopify } from '../data/shopify'
import ProductFilters, {useProductFilters} from '../components/ProductFilters'
import AboutMe from '../components/AboutMe'

import { dark, primaryDark, primary, secondaryDark } from '../styles/colors'
import { fromMedium } from '../styles/mediaQueries'
import { Product as ShopProduct } from '../data/shop'

import Product from '../components/shop/Product'
import links from '../data/links'
import { useSortedProducts } from '../utils/products'

const Products = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100vw;
  max-width: 1800px;
`

const InfoContainer = styled.div`
  margin-top: 1em;
  font-size: 1.3rem;
  padding: 1em;
  color: ${dark};
  text-align: center;
  width: 100%;

  ${fromMedium} {
    width: 20em;
  }
`

const ExternalLink = styled.a.attrs({
  target: '_blank',
  rel: 'noopener noreferrer'
})`
  color: ${primary};
  text-decoration: none;
  font-weight: 500;

  &:hover {
    color: ${primaryDark};
    text-decoration: underline;
  }

  &:active {
    color: ${secondaryDark};
  }
`

const Anthologies = styled.div`
  width: 100vw;
  max-width: 1800px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  color: ${dark};
  padding: 1em;
`

const AnthologyList = styled.ul`
  list-style: none;
  padding: 0;
`;

function Shop () {
  const {data: shopifyProducts, isLoading, isError} = useQuery<ShopProduct[], any>('shopify-products', () => getProductsFromShopify())
  const {data: etsyProductsMikan, isLoading: isLoadingMikan, isError: isErrorMikan} = useQuery<ShopProduct[], any>('etsy-products-mikan', () => getProductsFromEtsyByIds(['1159926180', '1159932148', '1173874093']))
  const {availableFilters, selectedFilters, toggleFilter, selectAllFilters} = useProductFilters(shopifyProducts)
  const sortedProducts = useSortedProducts(shopifyProducts)

  const filterUnselectedCategories = (product: ShopProduct) => {
    if (selectedFilters.length === availableFilters.length) {
      return true
    }

    const productCategories = product.collections as string[]
    const hasSelectedCategory = productCategories.some(category => selectedFilters.includes(category))
    return hasSelectedCategory
  }

  return (
    <Page>
      {(isLoading || isLoadingMikan) && <InfoContainer>Hold on tight for a few seconds: loading the good stuff!</InfoContainer>}
      {(isError || isErrorMikan) && <InfoContainer>Oops, there was an error while fetching the list of products... Please refresh the page or go straight to the <ExternalLink href={links.shopify}>Shopify shop</ExternalLink>!</InfoContainer>}

      {sortedProducts && (
        <>
          <ProductFilters
            availableFilters={availableFilters}
            selectedFilters={selectedFilters}
            toggleFilter={toggleFilter}
            selectAllFilters={selectAllFilters}
          />

          <Products>
            {sortedProducts?.filter(filterUnselectedCategories).map(product => (
              <Product product={product} key={product.id} />
            ))}
          </Products>
        </>
      )}

      {selectedFilters.includes('Anthologies') && (
        <Anthologies>
          <h2>Anthologies</h2>

          <p>The following anthologies have contributions from me:</p>

          <AnthologyList>
            <li><ExternalLink href="https://wipcomics.co.uk/l/wipxl">WIP XL (Newspaper Edition)</ExternalLink></li>
            <li><ExternalLink href="https://wipcomics.co.uk/l/wipxl_digital">WIP XL (Digital Edition)</ExternalLink></li>

            {etsyProductsMikan && etsyProductsMikan?.map(product => (
              <li key={product.id}><ExternalLink href={product.url}>{product.name}</ExternalLink></li>
            ))}
          </AnthologyList>
        </Anthologies>
      )}

      <AboutMe />
    </Page>
  )
}

export default Shop
