import { useQuery } from "react-query";
import styled from "styled-components";

import { getImagesForProduct, Product as ShopProduct, ProductImage } from "../../data/shop";
import { ButtonLink } from "../ui/Button";
import { dark, grey, light, primary, secondaryDark } from "../../styles/colors";
import { fromMedium, fromSmall } from "../../styles/mediaQueries";
import { body } from "../../styles/fonts";

type Props = {
  product: ShopProduct
  layout?: "compact" | "full"
  showDescription?: boolean
}

const ProductContainer = styled.article`
  display: flex;
  flex-direction: column;
  border-radius: 1em;
  overflow: hidden;
  background-color: ${light};
  color: ${dark};
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  margin: 0.8em 0 0.5em;
  /* font-weight: 400; */

  ${fromSmall} {
    width: 30rem;
  }

  ${fromMedium} {
    margin-bottom: 1.5em;
  }
`

const ImageContainer = styled.a`
  height: 14em;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Image = styled.img`
  min-height: 100%;
  max-width: 100%;
  object-fit: contain;
`

const Type = styled.span`
  font-size: 0.8em;
  color: ${grey};
  display: block;
  font-weight: 300;
`

const Description = styled.section`
  padding: 0.2em 1em;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2`
  font-family: ${body};
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
  color: ${primary};
`

const TitleLink = styled.a`
  color: ${primary};
  text-decoration: none;
  font-size: 0.8em;
`

const DescriptionText = styled.div`
  white-space: pre-wrap;
  margin: 1.5em 0;
  font-size: 0.8em;

  p {
    margin: 0;
    padding: 0 0;
  }
`

const Info = styled.aside<{$margin: boolean}>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: ${props => props.$margin ? '1em' : 'auto'};
  width: 100%;

  a {
    font-size: 0.8em;
  }
`

const InfoLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1em;
`

const Price = styled.span`
  font-size: 1.1em;
  color: ${secondaryDark};
`

const PriceFrom = styled.span`
  font-size: 0.8em;
  color: ${grey};
  margin-right: 0.3em;
`

const ItemsLeft = styled.span`
  font-size: 0.9em;
  color: ${grey};
  margin-left: 0.5em;
`

const maxParagraphs = 1;

const paragraphs = (html: string) => html.split('</p> <p>').slice(0,2).join('</p> <p>').split('</p>').map(p => p.replace('<p>', ''))

const title = (name: string) => name.replace(/\s\(.+\)/g, '')

export default function Product({product, layout = 'full', showDescription = false}: Props) {
  const {data: images, isLoading: isLoadingImages} = useQuery<ProductImage[], any>(['product-images', product.listingSource, product.id], () => getImagesForProduct(product));

  const formattedPrice = new Intl.NumberFormat('en-GB',
    { style: 'currency', currency: product.price.currency
  }).format(product.price.amount / product.price.divisor);

  const linkAttributes = {
    href: product.url,
    target: '_blank',
    rel: 'noopener noreferrer'
  }

  if (!images || isLoadingImages) return null

  return (
    <ProductContainer>
      <ImageContainer {...linkAttributes}>
        <Image src={images[0].thumbnail} alt={product.name} />
      </ImageContainer>

      <Description>
        <TitleLink {...linkAttributes}>
          <Title>{title(product.name)}</Title>
        </TitleLink>
        {product.type && <Type>{product.type}</Type>}

        {layout === 'full' && (
          <>
            {showDescription && (
              <DescriptionText>
                {paragraphs(product.description).slice(0, maxParagraphs).map((paragraph, index) => <p key={index} dangerouslySetInnerHTML={ {__html: paragraph} } />)}
              </DescriptionText>
            )}
    
            <Info $margin={!showDescription}>
              { linkAttributes.href ? (
                <>
                  <InfoLeft>
                    {product.multiplePrices && <PriceFrom>from</PriceFrom>}
                    <Price>{formattedPrice}</Price>
                    {product?.quantityAvailable === 0 && <ItemsLeft>(Sold out)</ItemsLeft>}
                  </InfoLeft>

                  <ButtonLink {...linkAttributes}>More info / Order</ButtonLink>
                  {/* <InfoLink {...linkAttributes}>Buy on {capitalCase(product.listingSource)}</InfoLink> */}
                </>
              ) : (
                <span><strong>Coming soon!</strong></span>
              )}
            </Info>
          </>
        )}
      </Description>
    </ProductContainer>
  )
}
