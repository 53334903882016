import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Page from '../components/Page'
import { dark } from '../styles/colors';

const Content = styled.div`
  margin: 2rem auto;
  max-width: 40rem;
  font-size: 1.8rem;
  color: ${dark};
  text-align: center;
`;

const Title = styled.h1`
  margin: .5em 0;
  font-size: 1.5em;
`
function Submit () {
  return (
    <Page>
      <Content>
        <Title>Page not found 😢</Title>

        <p>
          I think the page you are looking for does not exist!
        </p>

        <p>
          In case it has moved somewhere else, please try checking the <Link to="/">homepage</Link> or the navigation bar above.
        </p>
      </Content>
    </Page>
  )
}

export default Submit
