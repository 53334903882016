import { Link } from "react-router-dom";
import styled from "styled-components";
import { light } from "../styles/colors";
import { projects, type Project } from "../data/portfolio";

interface Props {
  currentProject: Project;
}

const Container = styled.nav`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const NavigationItem = styled(Link)`
  font-size: 1.2rem;
  font-weight: 500;
  background-color: ${light};
  padding: 0.5em 1em;
`;

export default function ProjectNavigation({ currentProject }: Props) {
  const currentProjectIndex = projects.findIndex(
    (project) => project.slug === currentProject.slug
  );

  const nextProjectSlug = currentProjectIndex === projects.length - 1 ? projects[0].slug : projects[currentProjectIndex + 1].slug;
  const previousProjectSlug = currentProjectIndex === 0 ? projects[projects.length - 1].slug : projects[currentProjectIndex - 1].slug;

  return (
    <Container>
      <NavigationItem to={`/portfolio/project/${previousProjectSlug}`}><i className="fa-solid fa-arrow-left"></i> Previous project</NavigationItem>
      <NavigationItem to={`/portfolio/project/${nextProjectSlug}`}>Next project <i className="fa-solid fa-arrow-right"></i></NavigationItem>
    </Container>
  );
}
