import { useState, useEffect } from 'react'
import { Product as ShopProduct } from '../data/shop'

const sortByFeaturedRank = (a: ShopProduct, b: ShopProduct) => {
  if (a.featuredRank < b.featuredRank) {
    return -1
  } else if (a.featuredRank > b.featuredRank) {
    return 1
  } else {
    return 0
  }
}

export function useSortedProducts(products?: ShopProduct[]): ShopProduct[] {
  const [sortedProducts, setSortedProducts] = useState<ShopProduct[]>([])

  useEffect(() => {
    if (products) {
      const sorted = [...products].sort(sortByFeaturedRank)
      setSortedProducts(sorted)
    }
  }, [products])

  return sortedProducts
}
